<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <bread-crumb
            :breadcrumbs="[
              { label: 'Командировки', routeName: 'Trip' },
              { label: 'Просмотр' },
            ]"
          ></bread-crumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h3 class="page-title">
              Командировка {{ service.where }} {{ Str.date(service.start) }}
            </h3>
            <DetailListView
              editRoute="TripEdit"
              :service="service"
              :columns="[
                {
                  field: 'where',
                  label: 'Куда',
                  icon: 'pi pi-exclamation-circle',
                },
                {
                  field: 'start',
                  label: 'Срок',
                  content: (data) => {
                    return (
                      Str.date(service.start) + ' - ' + Str.date(service.end)
                    );
                  },
                  icon: 'pi pi-calendar',
                },
                {
                  field: 'employees',
                  label: 'Сотрудники',
                  icon: 'pi pi-user',
                  slot: 'emplyeeList',
                },
                {
                  field: 'olderName',
                  label: 'Старший группы',
                  icon: 'pi pi-user',
                  slot: 'olderSlot',
                },
                {
                  field: 'reportText',
                  label: 'Отчет',
                  icon: 'pi pi-file',
                },
                {
                  field: 'reportFile',
                  label: 'Файл с отчётом',
                  icon: 'pi pi-file',
                  slot: 'reportFileLink',
                },
                {
                  field: 'return',
                  label: 'Результат отчёта',
                  icon: 'pi pi-check',
                  content: (data) => {
                    return returnText;
                  },
                },
              ]"
            >
              <template #olderSlot="{ data }">
                <router-link
                  v-if="service && service.older"
                  class="text-info link_underline"
                  :to="{ name: 'UserShow', params: { id: service.older } }"
                >
                  {{ data }}
                </router-link>
              </template>
              <template #emplyeeList="{ data }">
                <template v-for="(employee, i) in data" :key="i">
                  <router-link
                    class="text-info link_underline"
                    :to="{ name: 'UserShow', params: { id: employee.id } }"
                  >
                    {{ employee.surname }} {{ employee.name }}
                    {{ employee.second_name }} <br />
                  </router-link>
                </template>
              </template>
              <template #reportFileLink="{ data }">
                <a
                  class="text-info link_underline"
                  v-if="data !== null"
                  :href="data"
                  >Скачать отчет</a
                >
                <span v-else>Отсутствует</span>
              </template>
            </DetailListView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import Str from "/src/utils/Str";
import TripService from "../services/TripService";
import DetailListView from "@/components/ui/DetailListView.vue";

export default {
  components: {
    BreadCrumb,
    DetailListView,
  },
  data() {
    return {
      service: new TripService({}),
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
  },
  computed: {
    returnText: function () {
      switch (this.service.return) {
        case 0:
          return "Предоставлен";
        case 1:
          return "Принят";
      }
    },
  },
};
</script>
